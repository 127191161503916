import React from 'react';
import logo from './orange-ideas-logo.png';
import { Link } from 'react-router-dom';

const Header = () => (
  <header className="bg-white shadow-md py-4 sm:py-6">
    <div className="container mx-auto flex justify-center px-4 sm:px-6">
      <Link to="/">
        <img src={logo} alt="Orange Ideas Logo" style={{ maxWidth: '320px' }} />
      </Link>
      {/* <h1 className="text-2xl font-bold text-gray-800">Oil Millers Conclave 2024 Photos</h1> */}
    </div>
  </header>
);

export default Header;
