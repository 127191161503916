import React, { useState, useEffect, Suspense } from 'react';
import Masonry from 'react-masonry-css';

// Add these styles to your CSS file
const masonryStyles = `
.my-masonry-grid {
  display: flex;
  width: auto;
  gap: 16px;
}
.my-masonry-grid_column {
  background-clip: padding-box;
}
.image-card {
  margin-bottom: 16px;
  break-inside: avoid;
  position: relative;
}
.image-card img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  transition: transform 0.3s ease;
}
.image-card:hover img {
  transform: scale(1.02);
}
.image-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.image-card:hover .image-overlay {
  opacity: 1;
}
`;

// New constants at the top of the file
const API_BASE_URL = 'https://photo-search.orangeideas.in';
const API_ENDPOINTS = {
  images: `${API_BASE_URL}/images.php`,
  imageUrl: (filename) => `${API_BASE_URL}/images/${filename}`
};

const MASONRY_BREAKPOINTS = {
  default: 4,
  1100: 3,
  700: 2,
  500: 1
};

const PLACEHOLDER_IMAGE = 'https://via.placeholder.com/400x400?text=Image+Failed+to+Load';

const PhotosPage = () => {
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    // Add styles to head
    const styleSheet = document.createElement("style");
    styleSheet.innerText = masonryStyles;
    document.head.appendChild(styleSheet);

    const fetchPhotos = async () => {
      console.log('📸 Fetching photos from API...');
      try {
        const response = await fetch(API_ENDPOINTS.images);
        console.log('📡 API response status:', response.status);
        const data = await response.json();
        console.log('✅ Photos data:', data);
        
        if (!data.available_images || !Array.isArray(data.available_images)) {
          throw new Error('Invalid data format received from API');
        }
        
        setPhotos(data.available_images);
        setLoading(false);
      } catch (error) {
        console.error('❌ Error fetching photos:', error);
        setError('Failed to load photos. Please try again later.');
        setLoading(false);
      }
    };

    fetchPhotos();

    // Cleanup styles
    return () => {
      document.head.removeChild(styleSheet);
    };
  }, []);

  // Loading state
  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  // Error state
  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen text-red-500">
        {error}
      </div>
    );
  }

  // Empty state
  if (photos.length === 0) {
    return (
      <div className="flex items-center justify-center min-h-screen text-gray-500">
        No photos available
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Photo Gallery ({photos.length})</h1>
      
      <Masonry
        breakpointCols={MASONRY_BREAKPOINTS}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {photos.map((photo, index) => (
          <div key={index} className="image-card">
            <Suspense 
              fallback={
                <div className="animate-pulse bg-gray-200 rounded-lg" 
                     style={{ paddingBottom: '100%' }}
                />
              }
            >
              <img
                src={API_ENDPOINTS.imageUrl(photo)}
                alt={`Photo ${index + 1}`}
                loading="lazy"
                onError={(e) => {
                  console.error(`Failed to load image: ${photo}`);
                  e.target.src = PLACEHOLDER_IMAGE;
                }}
                className="transition-all duration-300 ease-in-out"
                onClick={() => setSelectedImage(photo)}
              />
              <div className="image-overlay">
                <span className="text-sm truncate block">{photo}</span>
              </div>
            </Suspense>
          </div>
        ))}
      </Masonry>

      {/* Lightbox Modal */}
      {selectedImage && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 p-4"
          onClick={() => setSelectedImage(null)}
        >
          <div 
            className="relative w-[80vw] h-[80vh] flex items-center justify-center"
            onClick={e => e.stopPropagation()}
          >
            <img 
              src={API_ENDPOINTS.imageUrl(selectedImage)}
              className="max-w-full max-h-full object-contain"
              alt="Selected"
            />
            {/* Close button */}
            <button 
              className="absolute top-[-40px] right-0 text-white text-xl p-2"
              onClick={() => setSelectedImage(null)}
            >
              ✕
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PhotosPage; 