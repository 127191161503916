import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className="bg-white shadow-md py-4 mt-5">
      <div className="container mx-auto text-center py-3">
        <p className="text-sm sm:text-base text-gray-500">
          ShowMy.Pics is Powered By{' '}
          <a href="https://peenak.com" className="text-blue-500 hover:text-blue-700 underline transition-colors duration-300">
            Peenak
          </a>
        </p>

        <nav className="mt-2">
          <ul className="flex justify-center space-x-4">
            <li>
              <Link to="/" className="text-gray-500 hover:text-gray-700 transition-colors duration-300">
                Home
              </Link>
            </li>
            <li>
              <Link to="/photos" className="text-gray-500 hover:text-gray-700 transition-colors duration-300">
                All Photos
              </Link>
            </li>
            <li>
              <Link to="/legal" className="text-gray-500 hover:text-gray-700 transition-colors duration-300">
                Legal
              </Link>
            </li>
            <li>
              <Link to="/credits" className="text-gray-500 hover:text-gray-700 transition-colors duration-300">
                Credits
              </Link>
            </li>
            <li>
              <Link to="/help" className="text-gray-500 hover:text-gray-700 transition-colors duration-300">
                Help
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </footer>
  );
}

export default Footer;
